// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as basicErrorController from './basicErrorController';
import * as chapter from './chapter';
import * as course from './course';
import * as fileController from './fileController';
import * as lesson from './lesson';
import * as section from './section';
import * as userController from './userController';
export default {
  basicErrorController,
  chapter,
  course,
  fileController,
  lesson,
  section,
  userController,
};
