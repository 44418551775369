/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useLocation } from '@reach/router';
import { Emitter } from '@white-matrix/event-emitter';
import cn from 'classnames';
import { CODE_URL } from 'gatsby-env-variables';
import React, { useEffect, useMemo, useState } from 'react';

import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { getCourseIdFromPath } from '@/utils/common';
import { useLanguage } from '@/utils/hooks';
import { getTokenData } from '@/utils/tokenUtils';

import { EditorCollapse } from './EditorCollapse';

export const startLearningEmitter = new Emitter<void>();

interface IFrameEditorProps {
  isInLessonDetailPage: boolean;
}
export function IFrameEditor(props: IFrameEditorProps) {
  const { isInLessonDetailPage } = props;
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const {
    showEditor,
    setShowEditor,
    splitPanelLeftRatio,
    isDragging,
    editorChain,
    editorWorkspaceId,
    setEditorChain,
    setEditorWorkspaceId,
    userInfo
  } = useAppStore();
  const width = useMemo(() => {
    if (!isMounted || !showEditor) return 0;
    return `calc((100vw - 80px) * (1 - ${splitPanelLeftRatio}))`;
  }, [isMounted, splitPanelLeftRatio, showEditor]);
  // 在course detail或者lesson detail 页面、并且用户登陆并且有课程绑定的workspaceId，才显示编辑器
  const isLogin = useMemo(() => !!userInfo?.userId, [userInfo?.userId]);
  const { pathname } = useLocation();
  const isInCourseDetailPage = useMemo(
    () => pathname.split('/').length === 3 && pathname.includes('/courses/'),
    [pathname]
  );
  const [token, setToken] = useState<string>();
  useEffect(() => {
    setToken(getTokenData()?.accessToken);
  }, [userInfo?.userId]);
  const courseId = useMemo(() => {
    if (!isLogin) return;
    if (isInCourseDetailPage || isInLessonDetailPage) {
      return getCourseIdFromPath();
    }
  }, [isInCourseDetailPage, isInLessonDetailPage, isLogin]);

  const { lang } = useLanguage();
  useEffect(() => {
    if (!courseId) {
      setEditorChain(undefined);
      setEditorWorkspaceId(undefined);
      return;
    }
    async function fetchCourseBindWorkspace() {
      const { data } = await api.course.queryDetailUsingGet({
        courseId: Number(courseId),
        lang
      });
      if (!data?.chains?.length) return;
      const chain = data.chains[0];
      // 获取对应 workspaceId
      const { data: progressData } = await api.course.getProgressUsingGet({
        courseId: Number(courseId)
      });
      const isCourseStarted = !!progressData;
      // 课程已经开始并且没有绑定的 workspace
      if (isCourseStarted && !progressData?.workspaceId) {
        // init workspace
        const { data: createWorkspaceData } =
          await api.course.createWorkspaceUsingPost({
            courseId: courseId as number
          });
        setEditorWorkspaceId(createWorkspaceData);
      } else {
        setEditorWorkspaceId(progressData?.workspaceId);
      }
      setEditorChain(chain);
    }
    fetchCourseBindWorkspace().catch(console.error);
    startLearningEmitter.event(() => {
      fetchCourseBindWorkspace().catch(console.error);
    });
    return () => {
      startLearningEmitter.dispose();
    };
  }, [courseId, lang, setEditorChain, setEditorWorkspaceId]);

  if (!isLogin || (!isInLessonDetailPage && !isInCourseDetailPage))
    return <></>;
  return (
    <div>
      <div
        className={cn(
          !showEditor || !isInLessonDetailPage || isDragging
            ? '-z-50 opacity-0'
            : 'z-[1] opacity-100',
          'relative right-auto top-auto hidden h-[600px] pl-0 sm:fixed sm:right-0 sm:top-[72px] sm:block sm:h-[calc(100vh_-_72px)]'
        )}
        style={{
          width
        }}
      >
        {editorChain && editorWorkspaceId && token && (
          <iframe
            src={`${CODE_URL}?id=${editorWorkspaceId}&chain=${editorChain}&from=edu&token=${encodeURIComponent(
              token
            )}`}
            allow="clipboard-read; clipboard-write"
            className="h-full w-full"
          />
        )}
      </div>
      {isInLessonDetailPage && (
        <EditorCollapse
          setShowEditor={setShowEditor}
          showEditor={showEditor || false}
        />
      )}
    </div>
  );
}
