/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { m } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useMemo } from 'react';

import CourseListIcon from '@/assets/images/courses/course-list.inline.svg';
import defaultPeopleImg from '@/assets/images/courses/default-people.png';
import { AttributeTag, CourseButton, getTags } from '@/components';
import { useTranslation } from '@/i18n';
import { getPathFromCourseId } from '@/utils/common';
import {
  COURSE_DEFAULT_COVER,
  UPCOMING_COURSE_DEFAULT_COVER
} from '@/utils/const';

import { ProgressSlider } from '../../course-detail/SectionCourseHeader/ProgressSlider';
interface ICourseListProps {
  className?: string;
  type:
    | 'in_progress'
    | 'to_be_explored'
    | 'upcoming'
    | 'guest_to_be_explored'
    | 'guest_upcoming';
  courseData?: API.CourseDTO[];
  progressData?: API.CourseProgressDTO[];
}
export function CourseList(props: ICourseListProps) {
  const { className, type, progressData = [], courseData = [] } = props;
  const { t } = useTranslation();
  const allCourses = useMemo(() => {
    if (type === 'in_progress') {
      // 只要开始学习，不管学没学完，都在in_progress
      return (
        courseData.filter((courseItem) => {
          if (courseItem.status === 'UPCOMING') {
            // 即将推出的课程
            return false;
          }
          const courseItemProgress = progressData.find(
            (progressItem) => progressItem.courseId === courseItem.courseId
          );
          return !!courseItemProgress;
        }) || []
      );
    }
    if (type === 'to_be_explored') {
      return (
        courseData.filter((courseItem) => {
          if (courseItem.status === 'UPCOMING') {
            // 即将推出的课程
            return false;
          }
          const courseItemProgress = progressData.find(
            (progressItem) => progressItem.courseId === courseItem.courseId
          );
          // 除了有学习进度的和即将推出的，其他的都是等待学习的课程
          return !courseItemProgress;
        }) || []
      );
    }
    if (type === 'upcoming' || type === 'guest_upcoming') {
      return (
        courseData.filter((courseItem) => courseItem.status === 'UPCOMING') ||
        []
      );
    }
    if (type === 'guest_to_be_explored') {
      // 对于访客来说，所有的课程，除了upcoming的，都是可以看到的
      return (
        courseData.filter((courseItem) => courseItem.status !== 'UPCOMING') ||
        []
      );
    }
    return [];
  }, [courseData, progressData, type]);

  //   const [showSeeAllButton, setShowSeeAllButton] = useState(false);
  //   useEffect(() => {
  //     if (allCourses.length > 3) {
  //       setShowSeeAllButton(true);
  //     }
  //   }, [allCourses.length]);

  //   const showCourses = useMemo(()=>{
  //     if(showSeeAllButton){

  //     }
  //   },[])

  //   const showSeeAll = useMemo(() => {
  //     if (allCourses.length > 3) {
  //       return true;
  //     }
  //   }, []);

  const title = useMemo(() => {
    if (type === 'in_progress') {
      return t('courses.title.in_progress');
    } else if (type === 'to_be_explored' || type === 'guest_to_be_explored') {
      return t('courses.title.to_be_explored');
    } else if (type === 'guest_upcoming' || type === 'upcoming') {
      return t('courses.title.upcoming_courses');
    }
  }, [type, t]);
  if (!allCourses.length) return <></>;
  return (
    <div className={cn(className, 'mx-auto w-full xl:w-[70.3%]')}>
      <m.div
        initial={{
          opacity: 0,
          translateY: -10
        }}
        whileInView={{ opacity: 1, translateY: 0 }}
        className={cn(
          'mb-10 flex w-fit items-center gap-x-2 border-b-[2px] border-solid border-primaryColor'
        )}
      >
        <CourseListIcon />
        <h2 className="text-xl font-medium leading-[1.5]">{title}</h2>
      </m.div>
      <ul className="flex flex-col gap-y-10 ">
        {allCourses?.map((item, index) => {
          const itemProgressData = progressData?.find(
            (progress) => progress.courseId === item.courseId
          );
          let progressNumber = 0;
          if (itemProgressData?.completed) {
            progressNumber = 100;
          } else if (
            itemProgressData?.learntLessons !== undefined &&
            item?.totalLessons !== undefined
          ) {
            progressNumber = Math.floor(
              (itemProgressData.learntLessons / item.totalLessons) * 100
            );
          }

          const chapterTitle = `Chapter ${
            itemProgressData?.chapterSequence || ''
          }: ${itemProgressData?.chapterName || ''}`;

          const isUpcoming = type === 'upcoming' || type === 'guest_upcoming';

          return (
            <m.li
              key={item.id}
              initial={{
                opacity: 0,
                translateY: -20
              }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                type: 'spring',
                duration: 1.5,
                delay: 0.3 + index * 0.1
              }}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (isUpcoming) return;
                  void navigate(
                    `/courses/${getPathFromCourseId(item.courseId)}`
                  );
                }}
              >
                <div
                  className={cn(
                    'relative flex flex-col items-center gap-y-4 overflow-hidden rounded-lg bg-[#0F1320] sm:flex-row',
                    'border-[1px] border-solid border-[rgba(0,0,0,0)] duration-300 hover:border-[#293242] hover:shadow-sm'
                  )}
                >
                  <div
                    className="aspect-[1] w-full overflow-hidden bg-cover bg-center sm:w-[331px] sm:min-w-[331px]"
                    style={{
                      backgroundImage: `url('${
                        item.cover ||
                        (isUpcoming
                          ? UPCOMING_COURSE_DEFAULT_COVER
                          : COURSE_DEFAULT_COVER)
                      }')`
                    }}
                  ></div>
                  <div className="absolute left-0 top-0 flex h-7 w-20 items-center justify-center gap-x-1 rounded-br-lg bg-[#35FF9E] text-sm">
                    <span className="text-[#005E3180] line-through">100$</span>
                    <span className="text-[#005E31]">{t('common.free')}</span>
                  </div>
                  <div className="shrink-1 flex-1 bg-[#0F1320] p-4 sm:p-0 sm:px-[30px]">
                    <h2 className="mb-2 text-2xl leading-[1.5] text-white sm:line-clamp-1">
                      {item.name}
                    </h2>
                    <p className="line-clamp-3 leading-[1.5] text-[#97A2B8]">
                      {item.description}
                    </p>
                    <div className="my-4 flex flex-row flex-wrap items-center gap-x-[10px] gap-y-2 overflow-hidden">
                      {getTags(item, 4).map((item, index) => (
                        <AttributeTag key={index} {...item} />
                      ))}
                    </div>
                    {type === 'in_progress' && (
                      <div className="my-[10px] w-full">
                        <h3 className="text-[13px] leading-[1.5] text-[#CDD5E0]">
                          {chapterTitle}
                        </h3>
                        <div className="flex flex-row gap-x-2">
                          <ProgressSlider
                            value={[progressNumber]}
                            className={cn(
                              'mr-2 !w-full',
                              progressNumber === 0 && 'ml-2'
                            )}
                            disabled={true}
                          />
                          <span className="text-base text-white">
                            {progressNumber}%
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="flex w-full flex-wrap items-center justify-between gap-y-2">
                      {item.includes?.student ? (
                        <div className="flex items-center justify-center gap-x-2 rounded-lg bg-[#256BEF1A] px-[14px] py-1">
                          <img
                            src={defaultPeopleImg}
                            alt="Course Track Bg"
                            width={68}
                          />
                          <span className="flex items-center justify-center rounded-2xl bg-primaryColor px-2 pt-[2px] text-xs text-white">
                            +{item.includes?.student || 11}
                          </span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {!isUpcoming && (
                        <CourseButton
                          courseId={item.courseId}
                          type="solid"
                          courseProgress={progressData?.find(
                            (progress) => progress?.courseId === item.courseId
                          )}
                          className={cn(
                            '!h-9 !w-[150px] !text-sm sm:!w-[175px]',
                            type.includes('guest') && 'mt-2'
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </m.li>
          );
        })}
      </ul>
    </div>
  );
}
