import cn from 'classnames';
import React, { useMemo } from 'react';

import { Empty } from '@/components/common/Empty';

import { CourseCard } from './CourseCard';
import { EduHistoryTabType } from './ProfileContent';
interface IEduCourseTabProps {
  className?: string;
  type?: EduHistoryTabType;
  courseData?: API.CourseDTO[];
  progressData?: API.CourseProgressDTO[];
}
export function EduCourseTab(props: IEduCourseTabProps) {
  const { className, type, courseData, progressData } = props;
  const listData = useMemo(() => {
    if (!courseData || !progressData) {
      return [];
    }
    if (type === 'inprogress') {
      // 正在进行中的课程
      return courseData.filter((item) => {
        const progressItem = progressData.find(
          (progressItem) => progressItem.courseId === item.courseId
        );
        if (progressItem?.completed === true) {
          return false;
        }
        if (progressItem?.chapterSequence !== undefined) {
          return true;
        }
        return false;
      });
    } else if (type === 'finished') {
      // 已经完成的课程
      return courseData.filter((item) => {
        const progressItem = progressData.find(
          (progressItem) => progressItem.courseId === item.courseId
        );
        if (progressItem?.completed === true) {
          return true;
        }
        return false;
      });
    } else {
      return [];
    }
  }, [courseData, progressData, type]);

  const isEmpty = useMemo(() => !listData.length, [listData]);
  return (
    <div className={cn(className)}>
      {isEmpty ? (
        <Empty />
      ) : (
        <div className="grid grid-cols-1 gap-[30px] py-6 md:grid-cols-[repeat(auto-fill,calc((100%_-_60px)_/_2))] md:gap-[30px] xl:grid-cols-[repeat(auto-fill,calc((100%_-_60px)_/_3))] xl:gap-5 3xl:grid-cols-[repeat(auto-fill,calc((100%_-_120px)_/_4))] 3xl:gap-[30px]">
          {listData.map((item) => {
            const courseProgressData = progressData?.find(
              (process) => process.courseId === item.courseId
            );
            return (
              <CourseCard
                key={item.id as number}
                courseData={item}
                courseProgressData={courseProgressData as API.CourseProgressDTO}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
