import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import Loading from '@/assets/images/courses/loading.inline.svg';
import { LessonStatus, PrimaryButton } from '@/components';
import { useTranslation } from '@/i18n';
import api from '@/services/api';
import { getPathFromCourseId } from '@/utils/common';
import { COURSE_DETAIL_PATH, ESearchParamKeys } from '@/utils/const';

interface ICompleteButtonProps {
  className?: string;
  lessonData?: API.LessonDTO;
  courseId: string;
  lessonStatus?: LessonStatus;
  refetchProgress: () => void;
}
export function CompleteButton(props: ICompleteButtonProps) {
  const { className, lessonData, courseId, lessonStatus, refetchProgress } =
    props;
  const { t } = useTranslation();
  const jumpToNextLesson = useCallback(() => {
    if (
      lessonData?.nextLessonChapterSequence === undefined ||
      lessonData?.nextLessonSectionSequence === undefined ||
      lessonData?.nextLessonSequence === undefined
    ) {
      void navigate(`${COURSE_DETAIL_PATH}/${getPathFromCourseId(courseId)}`);
    } else {
      void navigate(
        `${COURSE_DETAIL_PATH}/${getPathFromCourseId(
          courseId
        )}/${lessonData?.nextLessonChapterSequence?.toString()}?${
          ESearchParamKeys.SECTION_SEQUENCE
        }=${lessonData?.nextLessonSectionSequence?.toString()}&${
          ESearchParamKeys.LESSON_SEQUENCE
        }=${lessonData?.nextLessonSequence?.toString()}`
      );
    }
  }, [
    courseId,
    lessonData?.nextLessonChapterSequence,
    lessonData?.nextLessonSectionSequence,
    lessonData?.nextLessonSequence
  ]);

  const { mutate: completeLessonMutate, isLoading: isCompletingLesson } =
    useMutation(
      ['api.lesson.completeLessonUsingPost', refetchProgress, jumpToNextLesson],
      () =>
        api.lesson.completeLessonUsingPost({
          lessonId: Number(lessonData?.id)
        }),
      {
        onSuccess() {
          void refetchProgress();
          jumpToNextLesson();
        },
        onError(e) {
          console.error(e);
          toast.error(t('tip.error.complete_lesson'));
        }
      }
    );

  return (
    <PrimaryButton
      className={cn('mx-auto mb-8 mt-2 min-w-[180px] duration-200', className)}
      onClick={() => {
        if (isCompletingLesson) return;
        // 更新进度
        if (lessonStatus === 'unlocked' && lessonData?.id) {
          completeLessonMutate();
          // 执行make complete
        } else if (lessonStatus === 'completed') {
          // 执行jump to next lesson
          jumpToNextLesson();
        }
      }}
    >
      <span>
        {lessonStatus === 'completed'
          ? t('lesson.button.next')
          : t('lesson.button.make_complete')}
      </span>
      {isCompletingLesson && (
        <Loading width={16} height={16} className="ml-1 mt-[2px]" />
      )}
    </PrimaryButton>
  );
}
