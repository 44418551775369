/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import cn from 'classnames';
import React, { useMemo } from 'react';

import PassIcon from '@/assets/images/profile/pass.inline.svg';
import { CourseButton } from '@/components';
import { COURSE_DEFAULT_COVER } from '@/utils/const';

import { ProgressSlider } from '../course-detail/SectionCourseHeader/ProgressSlider';
interface ICourseCardProps {
  className?: string;
  courseData: API.CourseDTO;
  courseProgressData: API.CourseProgressDTO;
}
export function CourseCard(props: ICourseCardProps) {
  const { className, courseData, courseProgressData } = props;
  const progressNumber = useMemo(() => {
    if (courseProgressData?.completed) return 100;
    if (
      courseProgressData?.learntLessons !== undefined &&
      courseData?.totalLessons !== undefined
    ) {
      return Math.floor(
        (courseProgressData.learntLessons / courseData.totalLessons) * 100
      );
    }
    return 0;
  }, [
    courseProgressData?.completed,
    courseProgressData?.learntLessons,
    courseData?.totalLessons
  ]);

  const { completed } = courseProgressData;
  return (
    <div className={cn(className, 'relative rounded-lg bg-[#1F2A3E] p-5')}>
      {completed && (
        <PassIcon className="absolute right-[6px] top-[10px] w-14" />
      )}
      <div className="flex w-full gap-x-[10px]">
        {/* <DefaultCover className="h-[60px] w-[60px]" /> */}
        <div
          className="h-[60px] w-[60px] rounded-lg bg-cover bg-center"
          style={{
            backgroundImage: `url('${
              courseData.cover || COURSE_DEFAULT_COVER
            }')`
          }}
        ></div>
        <div className="flex-1">
          <h2 className="line-clamp-2 text-base leading-[27px]">
            {courseData.name}
          </h2>
        </div>
      </div>
      <div className="my-[10px]">
        <h3 className="line-clamp-1 text-[13px] leading-[1.5] text-[#CDD5E0]">
          {`Chapter ${courseProgressData?.chapterSequence || 1} : ${
            courseProgressData?.chapterName || ''
          }`}
        </h3>
        <div className="flex flex-row gap-x-2">
          <ProgressSlider
            value={[progressNumber]}
            className={cn('mr-2', progressNumber === 0 && 'ml-2')}
            disabled={true}
          />
          <span className="text-base text-white">{progressNumber}%</span>
        </div>
      </div>
      <p className="mb-[54px] line-clamp-5 w-full text-sm leading-[1.5] text-[#97A2B8]">
        {courseData.description}
      </p>
      <CourseButton
        courseId={courseData?.courseId}
        className="absolute bottom-5 left-5 right-5 !w-auto !text-sm"
        courseProgress={courseProgressData}
      />
    </div>
  );
}
