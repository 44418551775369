import cn from 'classnames';
import { m, Variants } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import ArrowDown from '@/assets/images/common/arrow-down.inline.svg';
import LanguageIcon from '@/assets/images/common/language.inline.svg';
import { switchLanguage, t, useTranslation } from '@/i18n';

interface LanguageSwitchProps {
  className?: string;
}

const containerVariants: Variants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0% round 10px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.15,
      staggerChildren: 0.05
    }
  },
  closed: {
    clipPath: 'inset(10% 50% 90% 50% round 10px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.3
    }
  }
};
const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};

export function LanguageSwitch(props: LanguageSwitchProps) {
  const { className } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const ref = useRef<HTMLElement>(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });

  const languageOption: Array<{ label: string; language: 'en-US' | 'zh' }> = [
    {
      label: t('language.en'),
      language: 'en-US'
    },
    {
      label: t('language.zh'),
      language: 'zh'
    }
  ];

  const handleSwitchLanguage = (language: 'en-US' | 'zh') => {
    switchLanguage(language);
    setIsOpen(false);
  };

  return (
    <m.nav
      ref={ref}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      className={cn('relative hidden sm:block', className)}
    >
      <m.button
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
        className="relative flex h-9 w-[120px] items-center justify-center gap-2 text-lg text-white duration-200 hover:opacity-75"
      >
        <LanguageIcon className="mr-[2px]" />
        {['zh', 'zh-CN'].includes(i18n.language) ? '中文' : 'EN'}
        <m.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 }
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55 }}
        >
          <ArrowDown />
        </m.div>
      </m.button>
      <m.ul
        variants={containerVariants}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
        className="absolute right-0 top-[54px] z-10 w-[140px] space-y-2 rounded-lg bg-[#1F2F4D]"
      >
        {languageOption.map((item) => (
          <m.li
            key={item.language}
            role="button"
            variants={itemVariants}
            onClick={() => handleSwitchLanguage(item.language)}
            className="px-5 py-4 text-lg leading-[1] hover:bg-[rgba(117,161,244,0.5)] hover:text-white/80"
          >
            {item.label}
          </m.li>
        ))}
      </m.ul>
    </m.nav>
  );
}
