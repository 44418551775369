// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Query Lesson By ID GET /edu/v1/lessons/${param0} */
export async function getLessonUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getLessonUsingGETParams,
  options?: { [key: string]: any },
) {
  const { lessonId: param0, ...queryParams } = params;
  return request<API.ResponseLessonDTO>(`/edu/v1/lessons/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Complete Lesson POST /edu/v1/lessons/${param0} */
export async function completeLessonUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.completeLessonUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { lessonId: param0, ...queryParams } = params;
  return request<API.ResponseVoid>(`/edu/v1/lessons/${param0}`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}
