// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Query Course List GET /edu/v1/courses */
export async function queryListUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.ResponseListCourseDTO>('/edu/v1/courses', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Query Course Detail GET /edu/v1/courses/${param0} */
export async function queryDetailUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  const { courseId: param0, ...queryParams } = params;
  return request<API.ResponseCourseDTO>(`/edu/v1/courses/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** Query Lesson By Sequence GET /edu/v1/courses/${param0}/chapters/${param1} */
export async function queryChapterUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryChapterUsingGETParams,
  options?: { [key: string]: any },
) {
  const { courseId: param0, chapterSequence: param1, ...queryParams } = params;
  return request<API.ResponseChapterDTO>(`/edu/v1/courses/${param0}/chapters/${param1}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** Query Lesson By Sequence GET /edu/v1/courses/${param0}/chapters/${param1}/sections/${param2}/lessons/${param3} */
export async function queryLessonUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryLessonUsingGETParams,
  options?: { [key: string]: any },
) {
  const {
    courseId: param0,
    chapterSequence: param1,
    sectionSequence: param2,
    lessonSequence: param3,
    ...queryParams
  } = params;
  return request<API.ResponseLessonDTO>(
    `/edu/v1/courses/${param0}/chapters/${param1}/sections/${param2}/lessons/${param3}`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}

/** Get Course Progress GET /edu/v1/courses/${param0}/progress */
export async function getProgressUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProgressUsingGETParams,
  options?: { [key: string]: any },
) {
  const { courseId: param0, ...queryParams } = params;
  return request<API.ResponseCourseProgressDTO>(`/edu/v1/courses/${param0}/progress`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** Start Course POST /edu/v1/courses/${param0}/start */
export async function startCourseUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.startCourseUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { courseId: param0, ...queryParams } = params;
  return request<API.ResponseVoid>(`/edu/v1/courses/${param0}/start`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Create course's workspace POST /edu/v1/courses/${param0}/workspace */
export async function createWorkspaceUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.createWorkspaceUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { courseId: param0, ...queryParams } = params;
  return request<API.Responsestring>(`/edu/v1/courses/${param0}/workspace`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Batch Get Course Progress POST /edu/v1/courses/progresses */
export async function getProgressesUsingPost(
  body: API.CourseIdsDTO,
  options?: { [key: string]: any },
) {
  return request<API.ResponseListCourseProgressDTO>('/edu/v1/courses/progresses', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
