/* eslint-disable @typescript-eslint/no-unsafe-call */
import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import Arrow from '@/assets/images/courses/button-arrow.inline.svg';
import Loading from '@/assets/images/courses/loading.inline.svg';
import { startLearningEmitter } from '@/components/common/IFrameEditor';
import { useTranslation } from '@/i18n';
import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { getPathFromCourseId, login } from '@/utils/common';
import {
  COURSE_DETAIL_PATH,
  DEFAULT_SEQUENCE,
  ESearchParamKeys
} from '@/utils/const';
interface ICourseButtonProps {
  className?: string;
  courseProgress?: API.CourseProgressDTO;
  courseId?: number;
  type?: 'linear' | 'solid';
}
export function CourseButton(props: ICourseButtonProps) {
  const { className, courseProgress, courseId, type = 'linear' } = props;
  const { userInfo } = useAppStore();
  const { t } = useTranslation();
  // const { setEditorChain, setEditorWorkspaceId } = useAppStore();
  const hasStarted = useMemo(() => !!courseProgress, [courseProgress]);
  const hasFinished = useMemo(
    () => !!courseProgress?.completed,
    [courseProgress?.completed]
  );

  const jumpToFirstLesson = useCallback(() => {
    if (courseId !== undefined) {
      void navigate(
        `${COURSE_DETAIL_PATH}/${getPathFromCourseId(
          courseId
        )}/${DEFAULT_SEQUENCE}?${
          ESearchParamKeys.SECTION_SEQUENCE
        }=${DEFAULT_SEQUENCE}&${
          ESearchParamKeys.LESSON_SEQUENCE
        }=${DEFAULT_SEQUENCE}`
      );
    }
  }, [courseId]);

  const jumpToCurrentLesson = useCallback(() => {
    const currentChapterSequence = courseProgress?.chapterSequence;
    const currentSectionSequence = courseProgress?.sectionSequence;
    const currentLessonSequence = courseProgress?.lessonSequence;
    if (
      currentChapterSequence !== undefined &&
      currentSectionSequence !== undefined &&
      currentLessonSequence !== undefined &&
      courseId !== undefined
    ) {
      void navigate(
        `${COURSE_DETAIL_PATH}/${getPathFromCourseId(
          courseId
        )}/${currentChapterSequence}?${
          ESearchParamKeys.SECTION_SEQUENCE
        }=${currentSectionSequence}&${
          ESearchParamKeys.LESSON_SEQUENCE
        }=${currentLessonSequence}`
      );
    }
  }, [
    courseId,
    courseProgress?.chapterSequence,
    courseProgress?.lessonSequence,
    courseProgress?.sectionSequence
  ]);

  const { mutate: startLearningMutate, isLoading: isStartingLearning } =
    useMutation(
      ['api.course.startCourseUsingPost', courseId, jumpToFirstLesson],
      () =>
        api.course.startCourseUsingPost({
          courseId: courseId as number
        }),
      {
        onError(e) {
          console.log(e);
          toast.error(t('tip.error.start_learning'));
        },
        onSuccess: () => {
          try {
            if (!courseId) return;
            startLearningEmitter.fire();
            jumpToFirstLesson();
          } catch (e) {
            console.error(e);
          }
        }
      }
    );

  const onClick = useCallback(() => {
    if (!userInfo) {
      login();
      return;
    }
    if (hasStarted) {
      // 已经开始
      if (hasFinished) {
        // 已经完成，点击继续学习，跳转到第一个chapter 第一个 section 第一个 lesson
        jumpToFirstLesson();
      } else {
        // 没有完成，点击继续学习，跳转到上次学习的章节
        jumpToCurrentLesson();
      }
    } else {
      // 没有开始，点击开始学习
      startLearningMutate();
    }
  }, [
    hasFinished,
    hasStarted,
    jumpToCurrentLesson,
    jumpToFirstLesson,
    startLearningMutate,
    userInfo
  ]);
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      className={cn(
        className,
        'flex h-11 w-[252px] items-center justify-center gap-x-1 rounded font-Manrope font-bold leading-6 text-white transition-all duration-200 hover:bg-opacity-80 hover:text-white/60 hover:shadow-xl',
        type === 'linear'
          ? 'bg-[linear-gradient(90deg,#256BEF_20.95%,#50F5FF_100%)]'
          : 'bg-primaryColor'
      )}
    >
      <span>
        {hasFinished
          ? t('common.button.review')
          : hasStarted
          ? t('common.button.keep_going')
          : t('common.button.start_learning')}
      </span>
      {isStartingLearning ? (
        <Loading width={20} height={20} className="ml-1 mt-[2px]" />
      ) : (
        <Arrow />
      )}
    </button>
  );
}
