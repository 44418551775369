import { useMemo } from 'react';

import { useTranslation } from '@/i18n';
export function useLanguage() {
  const { i18n } = useTranslation();
  const lang = useMemo(
    () => (['zh', 'zh-CN'].includes(i18n.language) ? 'zh' : 'en'),
    [i18n.language]
  );
  return {
    lang
  };
}
